import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme();

root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);

reportWebVitals();
