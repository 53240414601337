import React,{useState,useEffect,useCallback} from "react";
import {
    Grid,
    TextField,
    Autocomplete,
    CircularProgress,
    Typography,
    List,
    ListItem,
    Box,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getAddressFromContact } from "../commonFunctions";
import { getFullName } from '../utilsn';
import { debounce } from 'lodash';
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import createAxiosInstance from "../utils/axiosInstance";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { clearSearchTerm } from "../redux/slices/dashboardSlice";


const fetchContactSuggestions = async (query,orgId,setContactSuggestions,setLoadingSuggestions) => {
    if (!query) {
      setContactSuggestions([]);
      return;
    }
  
    setLoadingSuggestions(true);
    try {
      const resp = await createAxiosInstance().get(
        `api/search-contacts-with-query`,
        { params: { orgId,searchQuery: query,page: 1,pageSize: 20 } }
      );
      const newSuggestionArray = resp?.data?.data?.map((item) => ({
        ...item,
        id: item.id,
      }));
      setContactSuggestions(newSuggestionArray || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
    setLoadingSuggestions(false);
  };

const SearchContacts = ({orgId}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [contactSuggestions, setContactSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);

  const debouncedFetchContactSuggestions = useCallback(
    debounce((...args) => fetchContactSuggestions(...args), 500),
    []
  );
//   const {orgId} = useParams()
  useEffect(() => {
    if (!searchQuery) {
      const params = new URLSearchParams(location.search);
      params.delete("search");
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
      setContactSuggestions([]);
      debouncedFetchContactSuggestions.cancel();
    } else {
      debouncedFetchContactSuggestions(searchQuery,orgId,setContactSuggestions,setLoadingSuggestions);
    }
  }, [searchQuery, debouncedFetchContactSuggestions]);

  const handleClearSearch = () => {
    dispatch(clearSearchTerm());  
    setSearchQuery("");
    const params = new URLSearchParams(location.search);
    params.delete("search");
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Grid sx={{ ml:0 }}>
      <Autocomplete
        sx={{ '.MuiAutocomplete-inputRoot': {
          maxHeight: '48px',
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'flex'
          },
          width: {
            xs: '300px',
            sm: '250px',
            md: '420px',
          }
        }}}
        className="top-search-bar"
        // style={{ width: "420px" }}
        freeSolo
        // disabled={!isDisabled}
        value={searchQuery}
        options={contactSuggestions}
        filterOptions={(options) => options}
        getOptionLabel={(option) =>
          option?.id
            ? getFullName(option?.first_name, option?.middle_name, option?.last_name)
            : option
        }
        loading={loadingSuggestions}
        onInputChange={(event, newInputValue) => {
          setSearchQuery(newInputValue);
        }}
        onChange={(event, newValue) => {
          console.log("newValue",newValue);  
          if (typeof newValue === "string") {
            setSearchQuery(newValue);
            navigate(`/org/${orgId}/contacts?search=${newValue}`);
          } else if (newValue?.id) {  
            navigate(`/org/${orgId}/contacts/${newValue.id}`);
          } else {
            setSearchQuery("");
          }
        }}
        clearIcon={<CloseIcon onClick={handleClearSearch} fontSize="small" />}
        renderInput={(params) => (
          <TextField
            {...params}
            label={searchQuery ? "" : "Search Contacts"}
            className="ac-txt-field contact-search"
            style={{ backgroundColor: "white" }}
            variant="outlined"
            InputLabelProps={{
              shrink: false,
              color: "grey",
              style: {
                opacity: 0.6
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <SearchIcon style={{ color: "gray" }} />
              ),
              endAdornment: (
                <>
                  {loadingSuggestions ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                  if (searchQuery) {
                    navigate(`/org/${orgId}/contacts?search=${searchQuery}`);
                  }
                }
              }}
          />
        )}
        renderOption={(props, option) => {
            const fullName = getFullName(option?.first_name, option?.middle_name, option?.last_name);
            const address = getAddressFromContact(option);
            return (
            <ListItem {...props} key={option.id} button disableGutters sx={{
              '&.Mui-focusVisible,&:hover': {
                backgroundColor: '#2196f3 !important',
                color: 'white !important',
              },
            }}>
              <Box width="100%">
                <Typography className="ellipsis" variant="body1">
                    {fullName}
                </Typography>
                <Typography className="ellipsis" sx={{fontSize:"12px"
                }}>
                    {address}
                </Typography>
              </Box>
            </ListItem>
          )}}
      />
    </Grid> 
  );
};

export default SearchContacts;
