import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import createAxiosInstance from '../../utils/axiosInstance';

// Async thunk to fetch coordinates
export const fetchCoordinatesOfContactsInTasks = createAsyncThunk(
  'task/fetchCoordinates', 
  async ({ taskId, orgId, statusOfContact }, { rejectWithValue }) => {
    try {
      const response = await createAxiosInstance().get('/api/get-coordinates-of-contacts-in-tasks', {
        params: {
          taskId,
          orgId,
          ...(statusOfContact !== "DEFAULT" ? { status: statusOfContact } : {}),
        },
      });

      if (response?.data) {
        let contactCoordinatesN = response.data.allContacts
          .filter(item => item?.contact?.id && item?.contact?.latitude && item?.contact?.longitude)
          .map(item => ({
            key: item.contact.id,
            location: { lat: item.contact.latitude, lng: item.contact.longitude },
            label: `${item.contact.first_name} ${item.contact.last_name}`,
            contact: item.contact,
            status: item.status,
            address: `${item.contact.street_address}, ${item.contact.city}, ${item.contact.state} - ${item.contact.zip}`,
          }));
        return contactCoordinatesN; // This will be handled by extraReducers
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const taskSlice = createSlice({
  name: 'task',
  initialState: {
    progress: null,
    total_contacts: null,
    contactCoordinates: [],
    loading: false,
    error: null,
    isContactSliderOpen: false,
    notReachablePopUpOpen: false,
    selectedHouseHoldContacts: [],
  },
  reducers: {
    setCompletedCount: (state, action) => {
      state.progress = action.payload;
    },
    clearCompletedCount: (state) => {
      state.progress = null;
    },
    setTotalContacts: (state, action) => {
      state.total_contacts = action.payload;
    },
    clearTotalContacts: (state) => {
      state.total_contacts = null;
    },
    setContactSliderOpen: (state) => {
      state.isContactSliderOpen = true;
    },
    setContactSliderClose: (state) => {
      state.isContactSliderOpen = false;
    },
    setNotReachablePopUpOpen: (state) => {
      state.notReachablePopUpOpen = true;
    },
    setNotReachablePopUpClose: (state) => {
      state.notReachablePopUpOpen = false;
    },
    setSelectedHouseHoldContacts: (state,action) => {
      state.selectedHouseHoldContacts = action.payload;
    },
    clearSelectedHouseHoldContacts: (state) => {
      state.selectedHouseHoldContacts = [];
    },
    setTaskContactsLocations: (state,action) => {
      state.contactCoordinates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoordinatesOfContactsInTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoordinatesOfContactsInTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.contactCoordinates = action.payload;
      })
      .addCase(fetchCoordinatesOfContactsInTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setCompletedCount, clearCompletedCount, setTotalContacts, clearTotalContacts,setContactSliderOpen,setContactSliderClose,setNotReachablePopUpClose,setNotReachablePopUpOpen
  ,setSelectedHouseHoldContacts,clearSelectedHouseHoldContacts,setTaskContactsLocations
} = taskSlice.actions;

export const completedCount = (state) => state.task.progress;
export const totalContacts = (state) => state.task.total_contacts;
export const contactCoordinates = (state) => state.task.contactCoordinates;
export const isContactSlideOpen = (state) => state.task.isContactSliderOpen;
export const loading = (state) => state.task.loading;
export const error = (state) => state.task.error;

export default taskSlice.reducer;

