import React, { useEffect, useState, startTransition } from 'react';
import { Grid, Button, Typography, Link, CircularProgress } from '@mui/material';
import createAxiosInstance from '../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import banner from '../images/landing-banner.png';
import AppHeader from '../common/AppHeader';

const LandingPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);

  const fetchAuthUserInfo = async () => {
    try {
      const resp = await createAxiosInstance().get('/api/users/me');
      if (resp?.data?.id) {
        dispatch(setUser(resp?.data));
        navigate('/dashboard');
      } else {
        localStorage.removeItem('token');
        navigate('/');
      }
      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching user info:", error);
      localStorage.removeItem('token');
      navigate('/');
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchAuthUserInfo();
    } else {
      setShowLoading(false);
    }
  }, [token]);


  if (showLoading) {
    return (
      <Grid item xs={12} md={12} lg={12} sx={{ justifyContent: 'center', display: 'flex', marginTop: '100px' }}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className='cg-landing-hero-section'
        style={{ 
          backgroundImage: `url(${banner})`,
          backgroundPosition: 'center',
          padding: '0px 0px 40px 0px'
        }}
      >
        <AppHeader />
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          style={{
            background: 'rgba(0, 0, 0, 0.85)',
            padding: '40px',
            marginTop: '50px'
          }}
         >
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={7} lg={7} style={{ color: 'white', fontSize: '20px', lineHeight: '1.8', fontFamily: 'sans-serif !important', fontWeight: 600 }}>
              <Grid container justifyContent='center' padding='20px 0px'>
                <Grid item xs={11} md={12} lg={12}>
                  <div style={{ fontWeight: '800', fontSize: '32px' }}>
                    Welcome to CampaignGuru.ai
                  </div>
                  <div style={{ fontWeight: '400' }}>
                    <p>
                      In the fast-paced world of politics, having a well-structured campaign is crucial to achieving victory. At CampaignGuru.ai, we understand the unique challenges that political candidates face, and we are here to simplify the campaign process for you. Our cutting-edge platform is specifically designed to guide candidates through the intricate landscape of political campaigning by asking all the right questions.
                    </p>
                    <p>
                      Join us at CampaignGuru.ai and take the first step toward building a successful campaign that leads to victory. Empower your political aspirations today!
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid container justifyContent='center' padding='10px 0px'>
                <Grid item xs={11} md={12} lg={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: '20px', fontSize: '18px', fontWeight: '400' }}
                    onClick={() => startTransition(() => {
                      navigate('/login')
                    })}
                    type="button"
                  >
                    Click here to Sign In
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={10}
        justifyContent="center"
        alignItems="center"
        padding='10% 0px 5%'
      >
        <Grid item xs={11} md={10} lg={10}>
          <Grid
            container
            justifyContent='space-between'
            columnSpacing={10}
            rowSpacing={5}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" component="h5" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                    Craft Your Winning Strategy
                  </Typography>
                  <Typography variant="body1" component="p" style={{ color: 'gray' }}>
                    <p>
                      With CampaignGuru.ai, you'll be empowered to develop a robust campaign strategy tailored to your goals. Our platform will help you determine essential activities you need to perform, such as:
                    </p>
                    <p>
                      <span className='landing-usp-caption-sub'>Canvassing Requirements</span>: Calculate the number of doors you need to knock on to maximize voter outreach and engagement.
                      <br /><br /><span className='landing-usp-caption-sub'>Volunteer Management</span>: Identify the number of volunteers necessary to support your campaign efforts effectively and keep them organized.
                      <br /><br /><span className='landing-usp-caption-sub'>Fundraising Goals</span>: Assess the amount of funds you need to raise to fuel your campaign activities and outreach initiatives.
                      <br /><br /><span className='landing-usp-caption-sub'>Supporter Identification</span>: Pinpoint how many supporters you need to identify and convert into active participants in your campaign.
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" component="h5" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                    Real-Time Insights and Analytics
                  </Typography>
                  <Typography variant="body1" component="p" style={{ color: 'gray' }}>
                    <p>
                      Stay informed about your campaign's performance with our real-time analytics. CampaignGuru.ai provides you with:
                    </p>
                    <p>
                      <span className='landing-usp-caption-sub'>Progress Tracking</span>: Keep an eye on your campaign's progress and make data-driven decisions to optimize your strategy.Adjustments for Success: Receive actionable insights to help you pivot your approach when necessary, ensuring you remain on track to achieve your goals.
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" component="h5" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                    Seamless Communication Tools
                  </Typography>
                  <Typography variant="body1" component="p" style={{ color: 'gray' }}>
                    <p>
                      Our platform doesn't just help you plan; it equips you with the tools to execute your strategy. CampaignGuru.ai comes with an integrated voter database and communication capabilities, allowing you to:
                    </p>
                    <p>
                      <span className='landing-usp-caption-sub'>Engage Voters</span>: Reach out to potential supporters through targeted texting and email campaigns, ensuring your message resonates with the right audience.
                      <br /><br /><span className='landing-usp-caption-sub'>Monitor Engagement</span>: Track responses and interactions to refine your outreach efforts and improve voter engagement.
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" component="h5" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                    Your Partner in Political Success
                  </Typography>
                  <Typography variant="body1" component="p" style={{ color: 'gray' }}>
                    <p>
                      Whether you're running for a local office or a national position, CampaignGuru.ai is your trusted partner in navigating the complexities of political campaigns. Our platform is designed for candidates at all experience levels, making it easy for anyone to leverage the power of technology in their pursuit of public office.
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className='cg-footer'>
        <Grid item xs={12} md={12} lg={12} className='cg-footer-content'>
          <div style={{ paddingRight: '15px' }}>
            Copyright 2025 © CampaignGuru.ai. All rights reserved.
          </div>
          <Link href="/privacy-policy" style={{ textDecoration: 'none' }}>
            Privacy Policy
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
