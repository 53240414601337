import { AbilityBuilder, createMongoAbility } from '@casl/ability';

export const AppAbility = createMongoAbility;

export const defineAbilitiesFor = (user) => {
  const { can, rules } = new AbilityBuilder(AppAbility);
  if (user?.role === 'admin') {
    can('read',[
      'Dashboard',
      'Contacts',
      'ContactDetail',
      'AddContact',
      'ExportContacts',
      'MyPeople',
      'Settings',
      'TeamMembers',
      'AdminTasks',
      'Survey',
      'PendingReq',
      'TagsSection',
      'Segments',
      'ExportHistory',
      'ImportHistory',
      'EmailCampaign',
      'MyConnections',
      'ExportBtn'
    ]);

    can('manage',[
      'Contacts',
      'SavedSearch',
      'DashboardGoalEdit',
      'DashboardGoalDelete',
      'AddContact',
      'ImportContacts',
      'ContactMenu',
      'InviteMember',
      'Tags', 
      'DeleteTags',
      'DeleteContact',
      'AddDonation',
      'RemoveDonation',
      'AddGoal',
      'MyConnections',
      'BulkAction',
    ]);
  } else if (user?.role === 'team-manager') {
    can('read',[
      'AddContact',
      'Dashboard',
      'ContactDetail',
      'Contacts',
      'ExportContacts',
      'MyPeople',
      'Settings',
      'TeamMembers',
      'AdminTasks',
      'Survey',
      'Segments',
      'MyConnections'
    ]);

    can('manage',[
      'AddContact',
      'InviteMember',
      'Tags',
      'Contacts',
      'SavedSearch',
      'DeleteTags',
      'AddDonation',
      'RemoveDonation',
      'Tasks',
      'MyConnections'
    ]);

  } else if (user?.role === 'volunteer') {
    can('read',[
      'Dashboard',
      'ContactDetail',
      'Contacts',
      'MyTasks',
      'Settings',
      'MyConnections'
    ]);
    can('manage',[
      'Tags',
    ]);
  } else {
    can('read',[
      'Dashboard',
      'Contacts',
    ]);
  }
  return new AppAbility(rules);
};
